import './AdminPanel.scss';
import { Row, Tabs } from "antd";
import type { TabsProps } from 'antd';

import AddAttendance from './AddAttendance/AddAttendance';
import AttendanceRecords from './AttendanceRecords/AttendanceRecords';


const AdminPanel = () => {

    const tabContents: TabsProps['items'] = [
        {
            key: '1',
            label: 'Attendance Records',
            children: (<AttendanceRecords />)
        },
        {
            key: '2',
            label: 'Add Attendance',
            children: (
                <AddAttendance />
            )
        }
    ];

    
    return (
        <>
            <Row className="row-padded admin-panel">
                <Row className="admin-panel__container" justify="center" align="middle">
                    <Row className='admin-panel__tabs'>
                        <Tabs defaultActiveKey='1' items={tabContents} size="large"/>
                    </Row>
                </Row>
            </Row>
        </>
    );
};

export default AdminPanel;