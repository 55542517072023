import './AttendanceRecords.scss';
import { Row, Select, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { publicClientApplication } from "../../..";
import { AuthScopes } from "../../../AuthConfig";
import { getToken } from "../../../MSALExtensions";
import axios from "axios";

const EVENT_LIST_QUERY = `{
    event {
        contentItemId
        displayText
    }
}`;

interface AttendanceRecord {
    key: React.Key;
    username: string;
    firstName: string;
    lastName: string;
    attendedOn: Date;
}

const AttendanceRecords = () => {
    const columns: TableColumnsType<AttendanceRecord> = [
        {
          title: 'Username',
          dataIndex: 'username',
          sorter: (a, b) => a.username.localeCompare(b.username),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            title: 'Attended On',
            dataIndex: 'attendedOn',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.attendedOn).valueOf() - new Date(b.attendedOn).valueOf(),
            render: (d:Date) => new Date(d).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }),
        },
    ]

    const [eventList, setEventList] = useState([]);
    const [attendanceList, setAttendanceList] = useState([]);

    const selectEvent = (value: string) => {
        console.log(value);
       getAttendances(value);
    };

    const getAttendances = async (eventId: string) => {
        const accessToken = await getToken(publicClientApplication, AuthScopes.EventApi);
        const headers = {
            Authorization: `Bearer ${accessToken}`,
        };

        axios.get(`${process.env.REACT_APP_ACADEMY_EVENT_API}/Attendance/${eventId}`, { headers })
          .then((response) => {
              if (response.status === 200 && response.data) {
                console.log(response.data)
                setAttendanceList(response.data);
              }
          });
    }

    useEffect(() => {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_ORCHARD_API_URL}/api/graphql`,
            headers: {
                'Content-Type': 'application/graphql'
            },
            data: EVENT_LIST_QUERY
        };

        axios(config).then(function (response) {
            const result = response.data.data.event;
            if (result) {
                setEventList(result);
            }
            else {
                // TODO: no data front end
            }
        })
            .catch(function (error) {
                console.log(error);
            });

    }, []);
    
    return (
        <>
            <Row>
                <Select placeholder='Select Event' onChange={selectEvent} allowClear>
                    {eventList.map((e: any) => (
                        <Select.Option value={e.contentItemId}>{e.displayText}</Select.Option>
                    ))};
                </Select>
            </Row>
            <Row>
            <Table
                columns={columns}
                dataSource={attendanceList}
              />
            </Row>
        </>
    );
};

export default AttendanceRecords;